<template>
  <div class="copy">
    <p class="title" v-if="title" v-html="title"/>
    <div class="text" v-if="text" v-html="text"/>
    <p class="big-text" v-if="bigText" v-html="bigText"/>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      text: String,
      bigText: String
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .copy {
    color: #fff;
    padding: vw-relative(30 20, 320);
    @include breakpoint(medium) {
      padding: vw-relative(84) 0;
    }
  }
  .title {
    font: 300 #{vw-relative(18, 320)}/1 CenturyGothicPaneuropean;
    margin-bottom: .8em;
    @include breakpoint(medium) {
      font-size: vw-relative(36);
    }
  }
  .text {
    font: 300 #{vw-relative(16, 320)}/1.2 CenturyGothicPaneuropean;
    &::v-deep {
      & p {
        font: inherit;
        margin-bottom: 1em;
      }
      & ul {
        list-style-position: inside;
        margin-bottom: 1em;
      }
    }
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
  }
  .big-text {
    font: 300 #{vw-relative(16, 320)}/1.2 CenturyGothicPaneuropean;
    text-align: center;
    @include breakpoint(medium) {
      font-size: vw-relative(27);
    }
  }
</style>
