<template>
  <div class="quote" v-html="'&#8220;' + text + '&#8221;'"/>
</template>

<script>
  export default {
    props: {
      text: String
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .quote {
    height: 100%;
    display: flex;
    align-items: center;
    font: 300 #{vw-relative(17, 320)}/1.2 CenturyGothicPaneuropean;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    @include breakpoint(small only) {
      padding: vw-relative(30 20, 320);
    }
    @include breakpoint(medium) {
      font-size: vw-relative(32);
    }
  }
</style>
